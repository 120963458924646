/* eslint-disable no-unused-vars */
import gsap from 'gsap';

export default function () {
  const header = document.querySelector('.page-header--fixed');
  const subHeaderLists = document.querySelectorAll('.sub-header__list');
  let lastScrollTop = 0;

  function checkScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 120) {
      // Scroll down
      header.classList.add('page-header--collapsed');
      const tl = gsap.timeline();
      tl.to(subHeaderLists, {
        stagger: '0.2',
        duration: 0.2,
        autoAlpha: 0,
        y: '-10px',
      }).to(
        header,
        {
          duration: 0.5,
          y: '-80px',
          ease: 'power2.out',
        },
        '-=0.4',
      );
    } else if (scrollTop < 120) {
      const tl = gsap.timeline();
      tl.to(header, {
        autoAlpha: 1,
        y: '0px',
        ease: 'power2.out',
        duration: 0.5,
      }).to(subHeaderLists, {
        stagger: '0.4',
        duration: 0.4,
        autoAlpha: 1,
        y: '0px',
      });
      header.classList.remove('page-header--collapsed');
    } else if (lastScrollTop > scrollTop && scrollTop > 80) {
      const tl = gsap.timeline();
      tl.to(header, {
        autoAlpha: 1,
        y: '0px',
        ease: 'power2.out',
        duration: 0.5,
      }).to(subHeaderLists, {
        stagger: '0.4',
        duration: 0.4,
        autoAlpha: 1,
        y: '0px',
      });
    }
    lastScrollTop = scrollTop;
  }

  window.onscroll = () => {
    checkScroll();
  };

  window.addEventListener('load', () => {
    checkScroll();
  });

  window.addEventListener('scroll', checkScroll());
  checkScroll();

  const modal = window.modalManager.idModalMap.get('navigation');
  const mainNavTrigger = document.querySelector('.main-nav__trigger');
  modal.on('before-show', () => {
    mainNavTrigger.classList.add('main-nav__trigger--open');
  });
  modal.on('before-hide', () => {
    mainNavTrigger.classList.remove('main-nav__trigger--open');
  });
  mainNavTrigger.addEventListener('click', (e) => {
    if (e.target.classList.contains('main-nav__trigger--open')) {
      modal.hide();
    } else {
      modal.show();
    }
  });
}
