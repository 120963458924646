import Slider from './Slider';

export default function () {
  const defaultOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 30,
    preloadImages: true,
    updateOnImagesReady: true,
    freeMode: true,
    freeModeMomentumBounceRatio: 0.1,
    freeModeSticky: true,
    watchOverflow: true,
    loop: true,
    navigation: {
      nextEl: '.js-slider-next',
      prevEl: '.js-slider-previous',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  // Configuration overrides

  const configurations = new Map();

  configurations.set('slider--vertical', {
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 30,
    mousewheel: false,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  configurations.set('slider--custom-variable-height', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 100,
    mousewheel: false,
    loop: false,
    watchOverflow: true,
    navigation: {
      nextEl: '.slider--custom-variable-height .js-slider-next',
      prevEl: '.slider--custom-variable-height .js-slider-previous',
    },
    breakpoints: {
      768: {
        slidesPerView: 'auto',
      },
    },
  });

  configurations.set('slider--custom-full-width', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 100,
    mousewheel: false,
    loop: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.slider--custom-full-width .js-slider-next',
      prevEl: '.slider--custom-full-width .js-slider-previous',
    },
    breakpoints: {
      768: {
        slidesPerView: 1.1,
      },
    },
  });

  configurations.set('slider--case-description', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: false,
    loop: false,
    effect: 'fade',
    noSwiping: true,
    grabCursor: false,
    allowTouch: false,
    navigation: {
      nextEl: '.slider--case-image .js-slider-next',
      prevEl: '.slider--case-image .js-slider-previous',
    },
    fadeEffect: {
      crossFade: true,
    },
    watchOverflow: true,
  });

  configurations.set('slider--case-image', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 100,
    mousewheel: false,
    loop: false,
    watchOverflow: true,
    loopAdditionalSlides: 4,
    preloadImages: false,
    loopedSlides: 4,
    lazy: {
      loadPrevNextAmount: 4,
    },
    navigation: {
      nextEl: '.slider--case-image .js-slider-next',
      prevEl: '.slider--case-image .js-slider-previous',
    },
    breakpoints: {
      768: {
        slidesPerView: 1.05,
      },
    },
    on: {
      slideNextTransitionStart: () => {
        document.querySelector('.slider--case-description .swiper-container').swiper.slideNext();
      },
      slidePrevTransitionStart: () => {
        document.querySelector('.slider--case-description .swiper-container').swiper.slidePrev();
      },
    },
  });

  // Init sliders

  const sliders = [...document.querySelectorAll('.slider')];
  sliders.forEach((sliderEl) => {
    const sliderClasses = sliderEl.classList.toString();

    let slider;
    configurations.forEach((options, overrideClass) => {
      if (sliderClasses.indexOf(overrideClass) >= 0) {
        slider = new Slider(sliderEl, options);
        slider.init();
      }
    });
    if (!slider) {
      slider = new Slider(sliderEl, defaultOptions);
      slider.init();
    }
  });
}
