export default function () {
  const cmForms = document.querySelectorAll('.js-cm-form');

  if (cmForms) {
    [...cmForms].forEach((form) => {
      form.addEventListener('submit', () => {
        window.dataLayer.push({
          event: 'formSubmission',
          formTitle: document.title,
          formLabel: 'download-ebook',
        });
      });
    });
  }
}
