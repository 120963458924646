import SideBarLayout from './SidebarLayout';
import SideBarLayoutImage from './SidebarLayoutImage';

export default function () {
  [...document.querySelectorAll('.sidebar-layout:not(.sidebar-layout--image)')].forEach((el) => {
    const sidebarLayout = new SideBarLayout(el); // eslint-disable-line
    // console.log(sidebarLayout);
  });

  [...document.querySelectorAll('.sidebar-layout.sidebar-layout--image')].forEach((el) => {
    const sidebarLayout = new SideBarLayoutImage(el); // eslint-disable-line
    // console.log(sidebarLayout);
  });
}
