export default function () {
  const popupBars = [...document.querySelectorAll('.popup-bar')];
  popupBars.forEach((popupBar) => {
    const trigger = popupBar.querySelector('.popup-bar__trigger');

    trigger.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        popupBar.classList.toggle('open');
      }
    });

    trigger.addEventListener('click', () => {
      popupBar.classList.toggle('open');
    });

    document.addEventListener('click', (e) => {
      if (e.target !== popupBar && !popupBar.contains(e.target)) {
        popupBar.classList.remove('open');
      }
    });
  });
}
