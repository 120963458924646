export default function () {
  const videos = document.querySelectorAll(
    `iframe[src*="youtube.com/embed"],
    iframe[src*="youtube-nocookie.com/embed"],
    iframe[src*="player.vimeo"]`,
  );

  const squares = document.querySelectorAll('iframe[src*="my.mobilityplus.be"]');

  [...videos].forEach((video) => {
    const container = document.createElement('div');
    container.classList.add('video-container');
    video.parentNode.insertBefore(container, video);
    container.appendChild(video);
  });

  [...squares].forEach((square) => {
    const container = document.createElement('div');
    container.classList.add('iframe-container', 'iframe-container--map');
    square.parentNode.insertBefore(container, square);
    container.appendChild(square);
  });
}
