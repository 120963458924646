export default function () {
  const languageSelectors = [...document.querySelectorAll('.language-selector')];
  languageSelectors.forEach((languageSelector) => {
    const trigger = languageSelector.querySelector('.language-selector__trigger');

    trigger.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        languageSelector.classList.toggle('language-selector--open');
      }
    });

    trigger.addEventListener('click', () => {
      languageSelector.classList.toggle('language-selector--open');
    });

    document.addEventListener('click', (e) => {
      if (e.target !== languageSelector && !languageSelector.contains(e.target)) {
        languageSelector.classList.remove('language-selector--open');
      }
    });
  });
}
