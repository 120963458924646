/* eslint-disable no-unused-vars */
import 'classlist-polyfill';

import objectFitImages from 'object-fit-images';
import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import languageSelector from './components/layout/language-selector';
import floatingPrevious from './components/layout/floatingPrevious';
import validation from './components/forms/validation';
import ModalManager from './components/modals/ModalManager';
import sliders from './components/sliders/sliders';
import polyfill from './utils/polyfill';
import dropdownItem from './components/layout/dropdown-item';
import sidebarLayout from './components/sidebar-layout/init';
import newsletter from './components/forms/newsletter';
import cmForms from './components/forms/cmForms';
import AnimateOnScroll from './components/animateOnScroll/animateOnScroll';
import FileInput from './components/forms/fileInput';
import popupBar from './components/layout/popupBar';
import galleries from './components/gallery/galleries';
import accordions from './components/accordions/accordions';
import trackingEvents from './components/trackingEvents/trackingEvents';
import CookieNotification from './components/cookies/CookieNotification';

require('./utils/nativeConsole');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// Layout setup
languageSelector();
dropdownItem();
responsiveVideos();
sliders();
sidebarLayout();
newsletter();
cmForms();
FileInput();
popupBar();
galleries();
floatingPrevious();
accordions();
trackingEvents();
window.cookieNotification = new CookieNotification();

const animateOnScroll = new AnimateOnScroll({
  offset: 300,
  duration: 600,
  easing: 'ease-in-cubic',
});
// eslint-disable-next-line
window.modalManager = new ModalManager();
navigation();

// Forms
validation();

// Polyfills
polyfill();
objectFitImages();

// Enable this if you want to test ga calls in development
// gaDevelopment();

// Page specific classes
const pages = {};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

window.modalManager.checkModalOnPageLoad();
