import { slideToggle, slideDown, slideUp } from '../../utils/DOMAnimations';

export default class Accordion {
  constructor(element) {
    this.el = element;
    this.trigger = this.el.querySelector('.js-accordion__trigger');
    this.panel = this.el.querySelector('.js-accordion__panel');
    this.id = element.id || false;

    this.init();
  }

  init() {
    this.addEvents();

    if (this.id) {
      this.checkUrl();
    }
  }

  addEvents() {
    this.trigger.addEventListener('click', () => {
      this.trigger.setAttribute(
        'aria-expanded',
        this.trigger.getAttribute('aria-expanded') === 'false',
      );
      this.togglePanel();
      this.checkOtherAccordions();
    });
  }

  checkOtherAccordions() {
    const others = document.querySelectorAll('.js-accordion');
    [...others].forEach((el) => {
      this.deleteActiveState(el);
    });
  }

  checkParent() {
    const parent = this.el.parentNode.closest('.js-accordion');
    if (parent) {
      const trigger = parent.querySelector('.js-accordion__trigger');
      const panel = parent.querySelector('.js-accordion__panel');
      trigger.setAttribute('aria-expanded', trigger.getAttribute('aria-expanded') === 'false');
      this.open(panel);
    }
  }

  checkUrl() {
    if (window.location.href.indexOf(`#${this.id}`) > -1) {
      this.checkParent();
      this.open();
      const headerOffset = document.querySelector('.js-header').getBoundingClientRect().height;
      const topOffset = this.trigger.getBoundingClientRect().top - headerOffset - 30;

      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  }

  togglePanel() {
    slideToggle(this.panel);
  }

  open(el) {
    const panel = el || this.panel;
    slideDown(panel);
  }

  close(el) {
    const panel = el || this.panel;
    slideUp(panel);
  }

  deleteActiveState(el) {
    if (this.el !== el) {
      this.close(el.querySelector('.js-accordion__panel'));
    }
  }
}
