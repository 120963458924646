/* eslint-disable no-undef */

require('lightgallery.js/lib/js/lightgallery');
require('lightgallery.js/lib/js/lg-utils');

export default class Gallery {
  constructor(element, options) {
    this.element = element;
    this.options = options;

    this.init();
  }

  init() {
    this.gallery = lightGallery(this.element, this.options);
  }
}
