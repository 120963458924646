export default function () {
  const formEls = document.querySelectorAll('.form-newsletter');

  [...formEls].forEach((form) => {
    form.addEventListener('submit', () => {
      window.dataLayer.push({
        event: 'formSubmission',
        formTitle: document.title,
        formLabel: 'form-newsletter',
      });
    });
  });
}
