/* eslint-disable no-unused-vars */
// import Helpers from '../../utils/Helpers';
import Helpers from '../../utils/Helpers';

export default class SidebarLayout {
  constructor(domEl) {
    this.element = domEl;
    this.navigation = this.element.querySelector('.sidebar-layout__navigation ol');
    this.navigationEls = [...this.navigation.querySelectorAll('li')];
    this.content = this.element.querySelector('.sidebar-layout__content');
    this.groups = [...this.content.querySelectorAll('.sidebar-layout__group')];
    this.currentActiveGroup = this.groups[0]; // eslint-disable-line
    this.init();
  }

  init() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.navigationEls.forEach((navigationElement) => {
      const button = navigationElement.querySelector('.sidebar-layout__link');
      const target = button.getAttribute('data-target');
      const targetElement = document.querySelector(target);
    });

    window.addEventListener('scroll', () => {
      if (this.popup == null || !this.popup.classList.contains('hide')) {
        this.currentActiveGroup = this.groups[0]; // eslint-disable-line
        const navigationOffset = Helpers.getElementOffset(this.navigation);

        this.groups.forEach((group) => {
          const groupOffset = Helpers.getElementOffset(group);
          const currentActiveGroupOffset = Helpers.getElementOffset(this.currentActiveGroup);
          if (groupOffset.top <= navigationOffset.top + 150) {
            if (groupOffset.top >= currentActiveGroupOffset.top) {
              this.currentActiveGroup = group;
            }
          }
        });

        this.navigationEls.forEach((navigationEl) => {
          const {
            dataset: { groupId },
          } = navigationEl;
          if (groupId === this.currentActiveGroup.getAttribute('id')) {
            navigationEl.classList.add('active');
          } else {
            navigationEl.classList.remove('active');
          }
        });
      }
    });
  }
}
