/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import elementClosest from 'element-closest';

export default function () {
  // Closest polyfill
  elementClosest(window);

  const dropdownItems = [...document.querySelectorAll('.dropdown-item')];
  dropdownItems.forEach((dropdownItem) => {
    const trigger = dropdownItem;
    const container = dropdownItem.closest('.container');
    const dropdown = dropdownItem.querySelector('.dropdown-item__dropdown');

    trigger.addEventListener('keydown', (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        dropdownItem.classList.toggle('dropdown-item--open');
      }
    });

    trigger.addEventListener('mouseover', () => {
      const { offsetLeft } = dropdownItem;
      const ElementOffsetRight = window.innerWidth - dropdownItem.getBoundingClientRect().right;
      const containerOffsetRight = window.innerWidth - container.getBoundingClientRect().right;

      // width of dropdown is 450px
      if (offsetLeft <= 255 && container.offsetLeft <= 225 && ElementOffsetRight >= 450) {
        dropdown.style.left = '0px';
        dropdown.style.right = 'auto';
        dropdown.style.transform = 'translate(0, 0)';
        dropdownItem.classList.toggle('dropdown-item--open');
      } else if (ElementOffsetRight <= 255 && containerOffsetRight <= 225) {
        dropdown.style.left = 'auto';
        dropdown.style.right = '0px';
        dropdown.style.transform = 'translate(0, 0)';
        dropdownItem.classList.toggle('dropdown-item--open');
      } else {
        dropdownItem.classList.toggle('dropdown-item--open');
      }
    });

    trigger.addEventListener('mouseout', (e) => {
      // if (e.target !== dropdownItem && !dropdownItem.contains(e.target)) {
      dropdownItem.classList.remove('dropdown-item--open');
      // }
    });
  });
}
