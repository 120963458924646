export default function () {
  const trackingTargets = document.querySelectorAll('[data-gtm-track]');
  window.dataLayer = window.dataLayer || [];
  [...trackingTargets].forEach((target) => {
    target.addEventListener('click', () => {
      const event = target.hasAttribute('download') ? 'download' : 'button';
      const title = target.dataset.trackTitle ? target.dataset.trackTitle : document.title;
      const label = target.dataset.trackLabel ? target.dataset.trackLabel : target.textContent;

      window.dataLayer.push({
        event,
        [`${event}Title`]: title,
        [`${event}Label`]: label,
      });
    });
  });
}
