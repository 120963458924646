/* eslint-disable no-unused-vars */
import Accordion from './Accordion';

export default function () {
  const accordions = document.querySelectorAll('.js-accordion');

  [...accordions].forEach((elem) => {
    const accordion = new Accordion(elem);
  });
}
