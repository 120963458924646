import Gallery from './Gallery';

export default function () {
  const defaultOptions = {};

  // Configuration overrides

  const configurations = new Map();

  configurations.set('js-matrix-gallery', {
    selector: '.js-gallery__item',
    thumbnail: true,
  });

  // Init galleriess

  const galleries = [...document.querySelectorAll('.js-gallery')];
  galleries.forEach((galleryEl) => {
    const galleryClasses = galleryEl.classList.toString();

    let gallery;
    configurations.forEach((options, overrideClass) => {
      if (galleryClasses.indexOf(overrideClass) >= 0) {
        gallery = new Gallery(galleryEl, options);
        gallery.init();
      }
    });
    if (!gallery) {
      gallery = new Gallery(galleryEl, defaultOptions);
      gallery.init();
    }
  });
}
