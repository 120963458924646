export default () => {
  const floatingPrevious = document.querySelector('.js-floating-previous');
  const footer = document.querySelector('footer .container--footer-bottom');
  if (floatingPrevious) {
    window.addEventListener(
      'scroll',
      () => {
        if (window.scrollY > footer.offsetTop) {
          if (!floatingPrevious.classList.contains('floating-previous--hidden')) {
            floatingPrevious.classList.add('floating-previous--hidden');
          }
        } else {
          floatingPrevious.classList.remove('floating-previous--hidden');
        }
      },
      false,
    );
  }
};
