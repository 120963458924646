import { Swiper, Navigation, Mousewheel, A11y, EffectFade } from 'swiper/js/swiper.esm';

Swiper.use([Navigation, Mousewheel, A11y, EffectFade]);

export default class Slider {
  constructor(sliderEl, options) {
    this.element = sliderEl;
    this.options = options;
  }

  init() {
    this.swiper = new Swiper(this.element.querySelector('.swiper-container'), this.options);
  }
}
